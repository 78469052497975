<template>
  <LayoutBox title="历史未处理预警" style="cursor: pointer"
    ><ScrollTable
      ref="ScrollTable"
      :key="refreshKey"
      v-model="loading"
      is-global-query
      :columns="columns"
      :query="{
        status: '02',
        handleFlag: '01',
        isHistory: '01',
      }"
      :url="`/${$businessISG}/monitor/${prefix}/${prefix}-page`"
      @clickRow="
        ({ row }) => $refs.EarlyWarningHandleInfoDialog.openDialog({ row })
      "
    >
    </ScrollTable>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      :prefix="prefix"
    ></EarlyWarningHandleInfoDialog>
  </LayoutBox>
</template>

<script>
import EarlyWarningHandleInfoDialog from '@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog.vue';
export default {
  //组件注册
  components: {
    LayoutBox: () => import('@/views/screen/components/LayoutBox.vue'),
    ScrollTable: () => import('@/views/screen/components/ScrollTable'),
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'cash',
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
        },
        {
          title: '时间',
          field: 'warningTime',
          width: '40%',
        },
        {
          title: '监督类型',
          field: 'analysisItemText',
          width: '20%',
        },
        {
          title: '预警内容',
          field: 'description',
          width: '20%',
        },
      ],
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
    this.$bus.$on(`warning-success-${this.prefix}`, this.onSuccess);
  },
  //保存方法
  methods: {
    onSuccess() {
      if (this.$refs.ScrollTable) {
        this.$refs.ScrollTable.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
