var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{staticStyle:{"cursor":"pointer"},attrs:{"title":"历史未处理预警"}},[_c('ScrollTable',{key:_vm.refreshKey,ref:"ScrollTable",attrs:{"is-global-query":"","columns":_vm.columns,"query":{
      status: '02',
      handleFlag: '01',
      isHistory: '01',
    },"url":("/" + _vm.$businessISG + "/monitor/" + _vm.prefix + "/" + _vm.prefix + "-page")},on:{"clickRow":function (ref) {
        var row = ref.row;

        return _vm.$refs.EarlyWarningHandleInfoDialog.openDialog({ row: row });
}},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('EarlyWarningHandleInfoDialog',{ref:"EarlyWarningHandleInfoDialog",attrs:{"prefix":_vm.prefix}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }